import React, { useState, useEffect, useContext, Suspense } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import { getQS, saveAsFile } from '../../components/utilities/Utils';

import { USBGrid, USBColumn, USBSelect } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';

const FindByRequirementId = () => {

    const [loadinger, setLoading] = useState(false);
    const [requirementId, setRequirementId] = useState("");

    const findRequirementId= ()=>{
            window.location.href = `/FindByRequirementId?id=${requirementId}`
    }

    useEffect(() => {
        console.log("Loading");
        setLoading(true);
        setRequirementId(getQS('id')||"");

        const apiURL = `${endpoints.apigee
            }/document-management/cdc/requirements/v1/documents/${getQS('id')}/details`;
       if(getQS('id')){
        callApi
            .getApiDataRequirement(apiURL)
            .then((result) => {
                console.log(result.data);
                if (result?.data?.category === 'Closing Binder') {
                    window.location.href = `/ClosingBinders/Portfolio/Requirements?id=${getQS('id')}`
                } else  if (result?.data?.category?.toLowerCase() === 'invoice') {
                  window.location.href = `/Inbox/${getQS('id')}`
              } else if (result?.data?.category?.length>0) {
                    window.location.href = `/RequirementDetails?id=${getQS('id')}`
                }else{
                   alert("This Requirement Id is not found. Please search with valid Requirement Id.") 
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log('error', error)
                setLoading(false);
            });
        }

    }, [])

    return (
        <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            data-testid="breadcrumb"
            text="Reports"
            breadcrumbs={[
              { id: 1, text: 'Home', href: '/Redirect' },
              { id: 1, text: 'Find By Requirement Id', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid  data-testid="main" addClasses="content-frame content-bg">

        <USBColumn largeSpan={16}>

         
          <div className="sorting-inputs">
           
            <div className="filter-dropdowns-lookup searchInputValue">
              <label>
               Enter Requirement Id : <input
                  type="text"
                  data-testid="main" 
                  name="name"
                  id="searchInput"
                  size={40}
                  value={requirementId}
                  onChange={(e) => {
                    setRequirementId(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="filter-dropdowns-lookup searchButton">
              <USBButton
                type="button"
                variant="primary"
                size="small"
                id="searchButton"
                name="searchButton"
                disabled={false}
                handleClick={() => {
                    findRequirementId();
                }}
              >
                Search
              </USBButton>
            </div>
           <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
        </div>
        </USBColumn>
      </USBGrid>
    </>

    )
}

export default FindByRequirementId

