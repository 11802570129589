/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PlatformController from './PlatformController';
import axios from 'axios';
import { parseJwt, formatByBytes } from '../../components/utilities/Utils';
import rateLimit from 'axios-rate-limit';
import searchIcon from '../../../assets/img/search.png';
import {
  EDMDocumentUpload,
  DocumentView,
  FileItems,
  DocumentInfo,
  UploadView,
  FolderView,
  defaultContent,
} from '@usb-edm/react-document-upload';
import finalized2 from '../../../assets/img/finalized.png';
import greenTickImg from '../../../assets/img/green-tick.svg';
import dateAlertImg from '../../../assets/img/dateAlert.png';
import reuploadImg from '../../../assets/img/blueupload.png';
import loadingImg from '../../assets/Loading.gif';
import USBTooltip from '@usb-shield/react-tooltip';
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
  createContext,
} from 'react';
import dateAlert from '../../../assets/img/dateAlert.png';
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
  USBSelect,
} from '@usb-shield/components-react';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { TabView, TabPanel } from 'primereact/tabview';
import callApi from '../../components/utilities/callApi';
import Loader from '../../components/utilities/Loader';
import {
  getQS,
  saveAsFile,
  saveFileAsExcel,
} from '../../components/utilities/Utils';
import Messages from '../../components/organisms/Messages';
import { endpoints } from '../../../config/connections';
import {ExportToExcel} from '../../components/utilities/ExportToExcel';
import { DocContext } from '../context/DocContext';
import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';
import { IBDownloadsContext } from '../../components/Hooks/useDownloadStatusInbox';
// import './requirementdetails.css';
import './inbox.css';
// import './closingBinders.css';
import {
  USBIconShow,
  USBIconDownload,
  USBIconUpload,
  USBIconDelete,
  USBIconArrowUp,
} from '@usb-shield/react-icons';
import useUserRole from '../../components/Hooks/use-UserRole';
import useNetwork from '../../components/Hooks/useNetwork';
import { USBIconSuccess } from '@usb-shield/react-icons';
import { gridHeaders } from './GridHeaderInbox';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import useWarningMessage from './useWarningMessage';
import Select, { components } from 'react-select';
const platformController = new PlatformController();
const addAriaLabel = () => {
  const searchElement = document.getElementsByClassName('p-checkbox-box');
  const dropElement = document.getElementsByClassName('p-dropdown-trigger');
  for (let i = 0; i < searchElement.length; i += 1) {
    searchElement[i].setAttribute('aria-label', 'Mark as final');
  }
  for (let j = 0; j < dropElement.length; j += 1) {
    dropElement[j].setAttribute('aria-label', 'Click to show more');
  }
};

export const getClosingBindersListingNoAPI = (
  setReportingDetailsData,
  newData
) => {
  setReportingDetailsData(newData);
  addAriaLabel();
};

export const convert24HoursDate = (d) => {
  var dd;
  if (d.slice(-5) === 'PM CT') {
    var hrs = parseInt(d.slice(-11, -8));
    if (hrs != 12) {
      hrs += 12;
    }
    var mins = d.slice(-8, -6);
    dd = d.slice(0, 10) + ' ' + hrs + ':' + mins;
  } else if (d.slice(-5) === 'AM CT') {
    var hrs = parseInt(d.slice(-11, -8));
    if (hrs == 12) {
      hrs = 0;
    }
    var mins = d.slice(-8, -6);
    dd = d.slice(0, 10) + ' ' + hrs + ':' + mins;
  } else {
    console.log('Received Unrecognized Formatted String');
  }
  return new Date(dd);
};

export const getClosingBindersListing = async (
  setReportingDetailsData,
  platformController,
  requirementResults
) => {
  const listingId = getQS('id');
  let refreshRequired = false;

  const url = `${endpoints.api}/document-management/file-download/v1/documents/9fbf86b2-2a26-4dbe-a32a-2e2ba30af98d/files`;
  const https = rateLimit(
    axios.create({
      headers: {
        Accept: 'application/json',
        'Correlation-ID': `${sessionStorage.getItem(
          'loggedUser'
        )} ${sessionStorage.getItem('correlationId')}`,
        'Client-Application': 'CDC',
        Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        jwt: `${sessionStorage.getItem('tok')}`,
        'user-id': sessionStorage.getItem('remote_user')
          ? JSON.parse(sessionStorage.getItem('remote_user')).userID
          : `${sessionStorage.getItem('assignedUser')}`,
      },
    }),
    { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
  );
  const response = await https.get(url);
  if (response.data.length > 0) {
    const fullData = [];

    response.data.forEach((item) => {
      // fullData.push(item);
      try {
        if (item.status == 'Read') {
          refreshRequired = true;
        }
        fullData.push(item);
      } catch (error) {
        console.log(error);
      }
    });

    setReportingDetailsData(fullData);

    if (refreshRequired) {
      setTimeout(() => {
        getClosingBindersListing(setReportingDetailsData, platformController);
      }, 15000);
    }
    window.onbeforeunload = () => {
      sessionStorage.removeItem('searchData');
      sessionStorage.removeItem('key');
      sessionStorage.removeItem('direction');
    };
    addAriaLabel();
  }
};

export const getDocDetails = (
  setRequirementResults,
  setLoading,
  setDocTemplateFlag,
  setStatus
) => {
  setLoading(true);
  const apiURL = `${
    endpoints.apigee
  }/document-management/cdc/requirements/v1/documents/${getQS('id')}/details`;
  callApi
    .getApiDataRequirement(apiURL)
    .then((result) => {
      setRequirementResults(result.data);
      setStatus(result?.data?.status);
      if (result?.data?.status === 'New') {
        const url = `${
          endpoints.apigee
        }/document-management/cdc/requirements/v1/inbox/update/status?requirement-id=${getQS(
          'id'
        )}`;
        const https = rateLimit(
          axios.create({
            headers: {
              Accept: 'application/json',
              'Correlation-ID': `${sessionStorage.getItem(
                'loggedUser'
              )} ${sessionStorage.getItem('correlationId')}`,
              'Client-Application': 'CDC',
              Authorization: `Bearer ${sessionStorage.getItem('at')}`,
              jwt: `${sessionStorage.getItem('tok')}`,
              'user-id': sessionStorage.getItem('remote_user')
                ? JSON.parse(sessionStorage.getItem('remote_user')).userID
                : `${sessionStorage.getItem('assignedUser')}`,
            },
          }),
          { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
        );
        const response = https.post(url);
        if (response) {
          console.log('Status Updated Successfully');
        }
      }
      if (result.data.docTemplateFlg === 'true') {
        setDocTemplateFlag(true);
      } else {
        setDocTemplateFlag(false);
      }
      setLoading(false);
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
    });
};

export const duedateTemplate = (requirementResults) => {
  const docdueDate = moment(requirementResults.dueDate, 'YYYYMMDD').format(
    'YYYYMMDD'
  );
  let dateHtml;
  let dueHtml = '';
  const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
  if (requirementResults.dueDate != null) {
    const days = moment(docdueDate, 'YYYYMMDD').diff(
      moment(today, 'YYYYMMDD'),
      'days'
    );
    if (days < 0 && requirementResults.status === 'Not Submitted') {
      dueHtml = (
        <span className="font-red date-details">
          <img className="warn-img" src={dateAlert} alt="warning" />
          &nbsp;Past due
        </span>
      );
    }

    dateHtml = (
      <span>
        {moment(docdueDate).format('MMM DD, YYYY')}
        <span>{dueHtml}</span>
      </span>
    );
  } else dateHtml = '';
  return dateHtml;
};

export const handleWordCount = (
  event,
  setCommentTxt,
  setCommentCharsLeft,
  setCharCountWarn
) => {
  setCommentCharsLeft(event.target.value.length);
  if (event.target.value.length === 301) {
    setCharCountWarn(
      <span aria-live="assertive" role="alert" className="max300-char-error">
        Your message can&apos;t be more than 300 characters.
      </span>
    );
  } else {
    setCommentTxt(event.target.value);
    setCharCountWarn('');
  }
};

export const getAllMessages = (setLoading, setAllMessages) => {
  setLoading(true);
  const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getMsgApiURL = `${
    endpoints.apigee
  }/document-management/cdc/messaging/v1/messages/${getQS(
    'id'
  )}?userTimeZone=${tmZone}`;
  callApi
    .getApiDataRequirement(getMsgApiURL)
    .then((results) => {
      const msgResults = [];
      results.data.forEach((item) => {
        const temp1 = item?.commentsTimeStamp.replace('T', ' ');
        const temp2 = temp1?.split('.');
        const temp = item;
        const today = moment(new Date(), 'YYYYMMDD').format('MM/DD/YYYY');
        const commentdate = moment(item?.commentsTimeStamp).format(
          'MM/DD/YYYY'
        );
        temp.commentsTimeStamp = moment(temp2[0], 'yyyy-mm-dd HH:mm:ss').format(
          'hh:mm A'
        );
        if (commentdate === today) {
          temp.commentsDate = '';
        } else {
          temp.commentsDate = commentdate;
        }
        msgResults.push(temp);
      });
      setAllMessages(msgResults);
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};

export const submitMessageFn = (
  commentTxt,
  setCommentTxt,
  setCommentCharsLeft,
  setLoading,
  setAllMessages,
  setCharCountWarn
) => {
  if (commentTxt.length > 0) {
    setLoading(true);
    setCharCountWarn('');
    const data = {
      docId: getQS('id'),
      userId: sessionStorage.getItem('loggedUser'),
      comment: commentTxt,
    };
    const url = `${endpoints.apigee}/document-management/cdc/messaging/v1/documents/message`;
    return callApi
      .postApi(url, data)
      .then((res) => {
        setCommentTxt('');
        setCommentCharsLeft(0);
        getAllMessages(setLoading, setAllMessages);
      })
      .catch(() => {
        // console.log(error)
        history.push('/Error');
      });
  }
};

export const getAllMessagesPSA = (setLoading, setAllMessagesPSA) => {
  setLoading(true);
  const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getMsgApiURL = `${
    endpoints.apigee
  }/document-management/cdc/messaging/v1/messages/${getQS(
    'id'
  )}?userTimeZone=${tmZone}&userType=PSA`;
  callApi
    .getApiDataRequirement(getMsgApiURL)
    .then((results) => {
      const msgResults = [];
      results.data.forEach((item) => {
        const temp1 = item?.commentsTimeStamp.replace('T', ' ');
        const temp2 = temp1?.split('.');
        const temp = item;
        const today = moment(new Date(), 'YYYYMMDD').format('MM/DD/YYYY');
        const commentdate = moment(item?.commentsTimeStamp).format(
          'MM/DD/YYYY'
        );
        temp.commentsTimeStamp = moment(temp2[0], 'yyyy-mm-dd HH:mm:ss').format(
          'hh:mm A'
        );
        if (commentdate === today) {
          temp.commentsDate = '';
        } else {
          temp.commentsDate = commentdate;
        }
        msgResults.push(temp);
      });
      setAllMessagesPSA(msgResults);
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};
export const submitMessageFnPSA = (
  commentTxtPSA,
  setCommentTxtPSA,
  setCommentCharsLeftPSA,
  setLoading,
  setAllMessagesPSA,
  setCharCountWarnPSA
) => {
  if (commentTxtPSA.length > 0) {
    setLoading(true);
    setCharCountWarnPSA('');
    const data = {
      docId: getQS('id'),
      userId: sessionStorage.getItem('loggedUser'),
      comment: commentTxtPSA,
      userType: 'PSA',
    };
    const url = `${endpoints.apigee}/document-management/cdc/messaging/v1/documents/message`;
    return callApi
      .postApi(url, data)
      .then((res) => {
        setCommentTxtPSA('');
        setCommentCharsLeftPSA(0);
        getAllMessagesPSA(setLoading, setAllMessagesPSA);
      })
      .catch(() => {
        history.push('/Error');
      });
  }
};

export const getMessageCount = (user, setMessageCount) => {
  const listingId = getQS('id');
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${listingId}/notifications-count`;
  callApi
    .getApiDataRequirement(listUrl)
    .then((response) => {
      setMessageCount(response.data.count);
    })
    .catch(() => {
      // console.log(error)
    });
};

export const getMessageCountPSA = (user, setMessageCountPSA) => {
  const listingId = getQS('id');
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${listingId}/notifications-count?userType=PSA`;
  callApi
    .getApiDataRequirement(listUrl)
    .then((response) => {
      setMessageCountPSA(response.data.count);
    })
    .catch(() => {
      // console.log(error)
    });
};

export const activateTabActions = (
  e,
  setactiveTabIndex,
  setMessageCount,
  setMessageCountPSA,
  setLoading,
  setSearchValue
) => {
  setactiveTabIndex(e.index);
  // console.log(e.index)
  if (e.index === 1 || e === '1') {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
    setSearchValue('');
    setInterval(() => {
      setMessageCount(0);
    }, 3000);
  }

  if (e.index === 2 || e === '2') {
    setInterval(() => {
      setMessageCountPSA(0);
    }, 3000);
  }
};

export const closeMessage = (setFailedFlag, setSuccessFlag) => {
  setFailedFlag(false);
  setSuccessFlag(false);
};

export const closeModal = (setDeleteId, setErrorModal) => {
  setDeleteId('');
  setErrorModal(false);
};

export const closeAllmodal = (
  setErrorModal,
  setCloseRenameModal,
  setDocumentsModalOpen
) => {
  setErrorModal(false);
  setCloseRenameModal(false);
  setDocumentsModalOpen(false);
};

export const downloadDocTemplate = (setLoading, category, doctype) => {
  // docFilenetId
  let docSubType = '';

  setLoading(true);

  if (doctype === null || doctype === '') {
    docSubType = 'NA';
  } else {
    docSubType = doctype;
  }

  const apiURL = `${endpoints.api}/document-management/file-download/v1/documents/DocumentTemplates/files-filter?category=${category}&subType=${docSubType}`;
  callApi

    .getApiDataRequirement(apiURL)
    .then((result) => {
      const fileName = result.data.fileName.replace(
        'Content-Disposition: attachment; filename=',
        ''
      );
      saveAsFile(result.data.binary, fileName);
      setLoading(false);
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
    });
};

const InboxRequirement = () => {
  const ref = useRef();
  const handleDownload = (fileIds, totalFailed, showWarning, removeWarning) => {
    setDownloadFilterValue('all');
    let temp = filesList?.original;
    temp.push(...fileIds);
    setFileList({ original: temp, filtered: temp });
  };
  const sortByList = [
    { label: 'Select', value: '' },
    { label: 'File Name (A-Z)', value: 'fileName-Asc' },
    { label: 'File Name (Z-A)', value: 'fileName-Desc' },
    // { label: 'Document Title (A-Z)', value: 'document-Asc' },
    // { label: 'Document Title(Z-A)', value: 'document-Desc' },
    // { label: 'Uploaded Recently', value: 'upload-Asc' },
    // { label: 'Uploaded Earlier', value: 'upload-Desc' },
    // { label: 'Status (A-Z)', value: 'status-Asc' },
    // { label: 'Status (Z-A)', value: 'status-Desc' },
    { label: 'Type (A-Z)', value: 'type-Asc' },
    { label: 'Type (Z-A)', value: 'type-Desc' },
  ];

  const [sortBy, setSortBy] = useState('');
  const [status, setStatus] = useState('');
  const [loadinger, setLoading] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [activeTabIndex, setactiveTabIndex] = useState(0);
  const [actionsDrop, setActionsDrop] = useState('');
  const [requirementResults, setRequirementResults] = useState({
    metadata: {},
  });
  const [reportingDetailsData, setReportingDetailsData] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [allMessagesPSA, setAllMessagesPSA] = useState([]);
  const [messageCountPSA, setMessageCountPSA] = useState(0);
  const [successFlag, setSuccessFlag] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [failedFlag, setFailedFlag] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const [hideExcel, setHideExcel] = useState(false);
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [fileType, setFileType] = useState('');
  const [docTemplateFlag, setDocTemplateFlag] = useState(false);
  const [documentReport, setDocumentReport] = useState(false);
  const [Prompt, showWarning, removeWarning] = useWarningMessage();
  const { setCurrentMenu } = useContext(DocContext);
  const { permissionDetails } = useContext(PermissionRoleContext);
  const { filesList, setFileList } = useContext(IBDownloadsContext);
  // const { downloadType, setDownloadType} = useContext(CBDownloadsContext)
  const [showButton, setShowButton] = useState(false);
  const [downloadFilterValue, setDownloadFilterValue] = useState('all');
  const networkState = useNetwork();
  const { online, effectiveType } = networkState;

  const tempUserRole = useUserRole();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('fileNetIdLists')) {
      sessionStorage.removeItem('fileNetIdLists');
    }

    if (sessionStorage.getItem('fileDownloadIdLists')) {
      sessionStorage.removeItem('fileDownloadIdLists');
    }

    if (!sessionStorage.getItem('tok')) {
      if (getQS('id')) {

        
        localStorage.setItem('cbredurl', getQS('id'));
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
        if (getQS('from') || currentUrl.includes("dns.com")) {
          window.location.href = '/Employee';
        } else {
          window.location.href = '/';
        }
      }
    } else {
      setCurrentMenu('portfolio');
      localStorage.removeItem('cbredurl');

      if (sessionStorage.getItem('userrole') === 'employee') {
        setEmployee(true);
      }
      if (
        sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
        sessionStorage.getItem('servicingrole') === 'vendorcontributor' ||
        sessionStorage.getItem('servicingrole') === 'unlistedvendor'
      ) {
        setVendor(true);
      }

      getDocDetails(
        setRequirementResults,
        setLoading,
        setDocTemplateFlag,
        setStatus
      ); // setDocFilenetId
      getClosingBindersListing(
        setReportingDetailsData,
        platformController,
        requirementResults
      );
      getMessageCount(sessionStorage.getItem('assignedUser'), setMessageCount);
      getMessageCountPSA(
        sessionStorage.getItem('assignedUser'),
        setMessageCountPSA
      );
      const qsParam = getQS('index') ? getQS('index') : 0;
      if (qsParam === '1' || qsParam === '2') {
        activateTabActions(
          qsParam,
          setactiveTabIndex,
          setMessageCount,
          setMessageCountPSA,
          setSearchValue
        );
      }
      setactiveTabIndex(Number(qsParam));
      getAllMessages(setLoading, setAllMessages);
      getAllMessagesPSA(setLoading, setAllMessagesPSA);
    }
  }, []);

  useEffect(() => {
    if (reportingDetailsData.length > 0)
      setPluginsArray([...defaultPluginArray]);
  }, [reportingDetailsData]);

  let documentListID = [];
  const getTableData = (detailsData) => {
    return detailsData?.map((doc, index) => {
      documentListID.push(doc.documentMetadata[0].fileNetMetadata.id);
      let newDocument;
      newDocument = new DocumentInfo(
        `${doc.documentMetadata[0].fileNetMetadata.id}`,
        `${doc.documentMetadata[0].documentName}`,
        false,
        `10`
      );
      const uploadedElement = (
        <div className="uploadedElement">
          {/* <div>{doc.firstName && doc.firstName != "null" ? doc.firstName : ''} {doc.lastName && doc.lastName != "null" ? doc.lastName : ''}</div> */}
          {/* <div>{doc.documentMetadata[0].fileNetMetadata.email && doc.documentMetadata[0].fileNetMetadata.email != "null" ? doc.documentMetadata[0].fileNetMetadata.email: ''}</div> */}
          <div>
            {doc.documentMetadata[0].uploadDate &&
            doc.documentMetadata[0].uploadDate != 'null'
              ? doc.documentMetadata[0].uploadDate
              : ''}
          </div>
        </div>
      );
      const preview = (id) => {
        let type = 'IB';
        window.open(
          `/document-viewer?url=` + id + `&type=` + type + `&req=` + getQS('id')
        );
      };
      const previewDoc = (
        <div>
          {
            <Router>
              <Link
                to={location.pathname + location.search}
                onClick={() => {
                  const nameExt = doc.documentMetadata.documentName
                    ? doc.documentMetadata[0].documentName?.split('.').pop()
                    : doc.documentMetadata[0].documentName?.split('.').pop();
                  nameExt === 'pdf'
                    ? preview(doc.documentMetadata[0].fileNetMetadata.id)
                    : alert('Only uploaded pdf document can be previewed');
                }}
              >
                <USBIconShow
                  colorVariant={
                    (doc.documentMetadata.documentName
                      ? doc.documentMetadata[0].documentName?.split('.').pop()
                      : doc.documentMetadata[0].documentName
                          ?.split('.')
                          .pop()) === 'pdf'
                      ? 'interaction'
                      : 'grey60'
                  }
                  title="Preview"
                  desc="Preview File"
                  ariaHidden={true}
                  id="add-icon"
                  focusable={true}
                  tabindex={-1}
                />
              </Link>
            </Router>
          }
        </div>
      );
      const downloadDocument = (
        <div>
          <Router>
            <Link
              to={location.pathname + location.search}
              onClick={() => {
                const docsArray = [];
                docsArray.push({
                  id: doc.documentMetadata[0].fileNetMetadata.id,
                  name: doc.documentMetadata[0].documentName,
                  downloadStatus: 'Pending',
                  retryCount: 0,
                });

                handleDownload(docsArray);
              }}
            >
              <USBIconDownload
                colorVariant={'primary'}
                title="download"
                desc="download File"
                ariaHidden={true}
                id="add-icon"
                focusable={true}
                tabindex={-1}
              />
            </Link>
          </Router>
        </div>
      );
      // download

      // Setting column data
      newDocument.metadata = {
        name: newDocument.name,
        fileName: (
          <>
            <div id="docTitle">
              {newDocument.name ? newDocument.name : newDocument.name}
            </div>
            <div>
              {doc.fileSize && doc.fileSize != 'null'
                ? formatByBytes(doc.fileSize)
                : ''}
            </div>
          </>
        ),
        uploaded: uploadedElement,
        // uploaded: uploadedElement,
        // uploadStatus: statusElement,
        fileScan: doc.edmStatus,
        previwDoc: previewDoc,
        downloadDoc: downloadDocument,
      };
      return newDocument;
    });
  };

  const closeDocumentsModal = () => {
    setFileType('');
    setHideExcel(false);
    setDocumentReport(false);
    setDocumentsModalOpen(false);
  };

  const PluginContext = createContext(null);
  const ctx = { PluginContext };

  const DownloadPlugin = () => {
    // In your plugin component, get the context
    const pluginContext = useContext(ctx);
    return <div>Are you sure you want to Download selected documents?</div>;
  };

  const defaultPluginArray = [
    {
      visible: true,
      location: 'actions',
      plugin: <DownloadPlugin />,
      options: {
        labelText: 'Download',
        position: 1,
        iconNode: (
          <>
            <USBIconDownload></USBIconDownload>
          </>
        ),
        pluginState: useState(false),
        onActiveCheck: (selectType) => {
          if (selectType != 'NONE') {
            return true;
          } else {
            return false;
          }
        },
        onContextAction: ({
          selectedFileItems,
          addDocumentMessage,
          clearSelectedFileItems,
        }) => {
          console.log('slected files......', selectedFileItems);
          let docsArray = [];
          let totalFailed = 0;
          if (selectedFileItems.documents.length > 1) {
            for (let document of selectedFileItems.documents) {
              // if (document.metadata.uploadStatus.props.children[1].props.children == 'Read') {
              docsArray.push({
                id: document.id,
                name: document.name,
                downloadStatus: 'Pending',
                retryCount: 0,
              });
              // } else {
              //   totalFailed++;
              // }
            }
          } else {
            console.log('idllll', selectedFileItems.documents[0].id);
            docsArray.push({
              id: selectedFileItems.documents[0].id,
              name: selectedFileItems.documents[0].name,
              downloadStatus: 'Pending',
              retryCount: 0,
            });
          }

          if (docsArray.length > 0) {
            handleDownload(docsArray, totalFailed, showWarning, removeWarning);
            clearSelectedFileItems();
          } else {
            addDocumentMessage(
              'warning',
              'Documents in Failed/Reupload/Uploading statuses cannot be downloaded.',
              null,
              false
            );
          }
        },
        onDisabledClick: ({ addDocumentMessage }) => {
          addDocumentMessage(
            'warning',
            `There are no files selected to Download.`,
            null,
            false
          );
        },
      },
    },
  ];
  const [pluginsArray, setPluginsArray] = useState(defaultPluginArray);

  useEffect(() => {
    setPluginsArray([...defaultPluginArray]);
  }, [requirementResults]);

  const fileTypes = [{ label: 'Microsoft Excel (xlsx)', value: 'xlsx' }];

  const setDownloadFileType = (e, repType) => {
    let tempdata;
    if (repType === 'messages') {
      tempdata = [];
      allMessages.forEach((item) => {
        tempdata.push({
          CLOSING_BINDER_DATE:
            requirementResults?.closingBinderDate?.length > 0
              ? moment
                  .utc(requirementResults?.closingBinderDate)
                  .format('MMM DD, YYYY')
              : '',
          DEAL_NAME: requirementResults?.metadata?.dealName,
          DEAL_NUMBER: requirementResults?.metadata?.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults?.metadata?.reportingEntity,
          PARENT_COMPANY: requirementResults?.metadata?.parentCompanyName,
          NAME: item?.userName,
          COMMENT: `'${item?.comment}`,
          COMMENTED_AT: item?.commentsTimeStamp,
        });
      });
    } else if (repType === 'documents') {
      tempdata = [];
      reportingDetailsData.forEach((item) => {
        tempdata.push({
          CLOSING_BINDER_DATE:
            requirementResults?.closingBinderDate?.length > 0
              ? moment
                  .utc(requirementResults?.closingBinderDate)
                  .format('MMM DD, YYYY')
              : '',
          DEAL_NAME: requirementResults?.metadata?.dealName,
          DEAL_NUMBER: requirementResults?.metadata?.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults?.metadata?.reportingEntity,
          PARENT_COMPANY: requirementResults?.metadata?.parentCompanyName,
          NAME: item?.printfilename,
          UPLOADED_DATE: moment(item?.uploadedDate).format('MMM DD, YYYY'),
          UPLOADED_BY: item?.uploadedByName,
          FINAL:
            item?.MarkedasFinalValue === true
              ? 'Marked final'
              : 'Not marked final',
        });
      });
    }
    setFileType(e.target.value);
    setExcelData(tempdata);
    if (e.target.value === 'pdf') {
      setHideExcel(false);
    } else {
      setHideExcel(true);
    }
  };

  /* ============ Report download modal specific ends ========  */

  // Worksheet Tab API Calls

  const changeStatus = (status) => {
    if (status !== 'Select') {
      setSuccessFlag(false);
      setFailedFlag(false);
      const listingId = getQS('id');
      setLoading(true);

      const url = `${endpoints.envUrl}/document-management/closing-binders/v1/update/status?requirementId=${listingId}&status=${status}`;
      const https = rateLimit(
        axios.create({
          headers: {
            Accept: 'application/json',
            'Correlation-ID': `${sessionStorage.getItem('loggedUser')}`,
            'Client-Application': 'CDC',
            'user-id': `${
              parseJwt(
                sessionStorage.getItem('tok')
              ).custom_data.role.toUpperCase() ===
              'APP_8083_CDCWEB_SUPER_ADMINS'
                ? sessionStorage.getItem('assignedUser')
                : parseJwt(sessionStorage.getItem('tok')).sub
            }`,
            Authorization: `Bearer ${sessionStorage.getItem('at')}`,
            jwtToken: `${sessionStorage.getItem('tok')}`,
          },
        }),
        { maxRequests: 1, perMilliseconds: 1000, maxRPS: 7 }
      );

      https
        .post(url)
        .then((response) => {
          if (response.status === 200) {
            setSuccessFlag(true);
            getDocDetails(
              setRequirementResults,
              setLoading,
              setDocTemplateFlag,
              setStatus
            );
            setMessageContent(`Status changed to ${status} successfully`);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            getDocDetails(
              setRequirementResults,
              setLoading,
              setDocTemplateFlag,
              setStatus
            );
            setTimeout(() => {
              setSuccessFlag(false);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setFailedFlag(true);
          setMessageContent(
            `We are unable change the status.Please try after sometime. `
          );

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
      setLoading(false);
    }
  };

  const sortCBData = (searchedData) => {
    let key = sortBy?.split('-')[0];
    let direction = sortBy?.split('-')[1];
    sessionStorage.setItem('key', key);
    sessionStorage.setItem('direction', direction);
    if (key != 'Select' && searchedData?.length > 0) {
      let tempList = searchedData;
      console.log('tesmpppppppppppppp', tempList);
      switch (key) {
        case 'type':
          tempList.sort((a, b) => {
            if (direction == 'Asc') {
              return (
                a.documentMetadata[0].documentName?.split('.').pop() + ''
              ).localeCompare(
                b.documentMetadata[0].documentName?.split('.').pop() + ''
              );
            } else {
              return (
                b.documentMetadata[0].documentName?.split('.').pop() + ''
              ).localeCompare(
                a.documentMetadata[0].documentName?.split('.').pop() + ''
              );
            }
          });
          break;
        case 'fileName':
          tempList.sort((a, b) => {
            if (direction == 'Asc') {
              return (a.documentMetadata[0].documentName + '').localeCompare(
                b.documentMetadata[0].documentName + ''
              );
            } else {
              return (b.documentMetadata[0].documentName + '').localeCompare(
                a.documentMetadata[0].documentName + ''
              );
            }
          });
          break;
        case 'document':
          tempList.sort((a, b) => {
            if (direction == 'Asc') {
              return (a.renamedFileName + '').localeCompare(
                b.renamedFileName + ''
              );
            } else {
              return (b.renamedFileName + '').localeCompare(
                a.renamedFileName + ''
              );
            }
          });
          break;

        case 'upload':
          tempList.sort((a, b) => {
            console.log(a, 'sort');
            var dateA = convert24HoursDate(a.createdDate);
            var dateB = convert24HoursDate(b.createdDate);
            if (direction == 'Asc') {
              return dateA.getTime() < dateB.getTime() ? 1 : -1;
            } else {
              return dateA.getTime() > dateB.getTime() ? 1 : -1;
            }
          });
          break;
        case 'status':
          tempList.sort((a, b) => {
            if (direction == 'Asc') {
              return (a.edmStatus + '').localeCompare(b.edmStatus + '');
            } else {
              return (b.edmStatus + '').localeCompare(a.edmStatus + '');
            }
          });
          break;
        default:
          break;
      }
      return tempList;
    }
  };

  function globalSearch() {
    return reportingDetailsData?.filter(
      (item) =>
        (item?.documentMetadata[0].documentName?.split('.').pop() + '')
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        (item?.documentMetadata[0].documentName + '')
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        (item?.documentMetadata[0].documentName + '')
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        (item?.documentMetadata.firstName + item?.lastName + '')
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        (item?.documentMetadata[0].fileNetMetadata.edmStatus + '')
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
    );
  }

  const searchedData = globalSearch();
  const sortedData = sortCBData(searchedData);
  let timer;
  const IconOption = () => (
    <div>
      {' '}
      <img src={searchIcon} />{' '}
    </div>
  );

  function triggerSearch(value) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchValue(value);
    }, 200);
  }

  return (
    <>
      {Prompt}
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('remote_user') ? (
            <div className="warning-panel-remote-req-details">
              You are impersonating the user:{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
              exit, click the 'Exit User' link above.
            </div>
          ) : (
            ''
          )}
        </USBColumn>
        <USBColumn largeSpan={8}>
          {showButton && (
            <div className="showButton">
              <USBButton
                id="actualButton"
                variant="utility"
                handleClick={() => {
                  window.scrollTo({
                    top: 100,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                {/* Scroll Top */}
                <USBIconArrowUp />
              </USBButton>
            </div>
          )}
          <USBBreadcrumb
            id="details"
            text="Home"
            breadcrumbs={
              sessionStorage.getItem('role').toUpperCase() ===
              'APP_8083_CDCWEB_SUPER_ADMINS'
                ? [
                    {
                      id: 1,
                      text: 'Inbox portfolio',
                      href: '/Inbox',
                    },
                    { id: 2, text: 'Inbox', href: '' },
                  ]
                : [
                    {
                      id: 1,
                      text: 'My Portfolio',
                      href: '/ReportingDashboard',
                    },
                    { id: 2, text: 'Inbox', href: '' },
                  ]
            }
          />
        </USBColumn>
        <USBColumn largeSpan={8} />
      </USBGrid>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          {loadinger ? <Loader /> : null}

          <USBModal className="download-modal" isOpen={documentsModalOpen}>
            <USBModalCloseIcon
              handleModalClose={() => closeDocumentsModal()}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={modalTitle} />
            <USBModalBody>
              <div className="modal-description">
                To download the report, first choose your preferred format from
                the&nbsp;
                <strong>File type</strong>
                &nbsp;menu
              </div>
              <div>
                <div className="download-file-type-dropdown">
                  <Dropdown
                    value={fileType}
                    options={fileTypes}
                    onChange={
                      documentReport
                        ? (e) => setDownloadFileType(e, 'documents')
                        : (e) => setDownloadFileType(e, 'messages')
                    }
                    placeholder="File Type"
                    className="file-type"
                    ariaLabel="file type"
                  />
                </div>
              </div>
            </USBModalBody>
            <USBModalFooter>
              <div className="reportDownloadModal">
                <span className="report-cancel-btn">
                  <USBButton handleClick={() => closeDocumentsModal()}>
                    Cancel
                  </USBButton>
                </span>
                {hideExcel && (
                  <USBButton
                    variant="primary"
                    handleClick={
                      documentReport
                        ? () =>
                            ExportToExcel(
                              Object.values(excelData),
                              'Document_History',
                              setDocumentsModalOpen,
                              setHideExcel
                            )
                        : () =>
                            ExportToExcel(
                              Object.values(excelData),
                              'Messaging_History',
                              setDocumentsModalOpen,
                              setHideExcel
                            )
                    }
                  >
                    Download
                  </USBButton>
                )}
              </div>
            </USBModalFooter>
          </USBModal>

          {successFlag || failedFlag ? (
            <div
              className="message-content"
              data-testid="messageContent"
              role="alert"
            >
              <Messages
                success={successFlag}
                closeMessage={closeMessage}
                singleUploadFail={failedFlag}
                messageContent={messageContent}
                setFailedFlag={setFailedFlag}
                setSuccessFlag={setSuccessFlag}
                severity={'high'}
                errorContent={errorContent}
              />
            </div>
          ) : (
            ''
          )}

          <div className="tab-view-content-Inbox">
            <div className="closing-binder-title"> Document Inbox</div>
            <div className="sub-head-small">
              {/* <div className="date-status-details">
                <span className="font-bold light-black">Due date:</span>
                <span className="font-grey">
                  {duedateTemplate(requirementResults)}
                </span>
              </div> */}
              <div className="date-status-details"></div>
            </div>
            <div className="page-sub-frame">
              <div className="subheading-report-wrap">
                <div className="sub-heading-Inbox">
                  <div className="date-status-details">
                    <span className="font-bold light-black">Status:</span>
                    <span className="font-grey">
                      {requirementResults?.status}
                    </span>
                  </div>
                  {requirementResults?.metadata?.dealName === null ||
                  requirementResults?.metadata?.dealName === '' ? (
                    ''
                  ) : (
                    <div className="date-status-details">
                      <span className="font-bold light-black">Deal name:</span>
                      <span className="font-grey">
                        {requirementResults?.metadata?.dealName}
                      </span>
                    </div>
                  )}
                  {requirementResults?.metadata?.dealId === null ||
                  requirementResults?.metadata?.dealId === '' ? (
                    ''
                  ) : (
                    <div className="date-status-details">
                      <span className="font-bold light-black">
                        Deal number:
                      </span>
                      <span className="font-grey">
                        {requirementResults?.metadata?.dealId}
                      </span>
                    </div>
                  )}
                  {requirementResults?.metadata?.reportingEntity === null ||
                  requirementResults?.metadata?.reportingEntity === '' ? (
                    ''
                  ) : (
                    <div className="date-status-details">
                      <span className="font-bold light-black">
                        Legal entity:
                      </span>
                      <span className="font-grey">
                        {requirementResults?.metadata?.reportingEntity}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {reportingDetailsData.length === 0 ? (
              <div></div>
            ) : (
              <div>Total Files - {reportingDetailsData.length} &nbsp;</div>
            )}

            <div className="Inbox-view">
              <div className="cb-actions">
                <div className="sortByDropdownIB">
                  <Select
                    defaultValue={[]}
                    placeholder={'Global Search'}
                    isSearchable={true}
                    onInputChange={triggerSearch}
                    styles={{
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '1em',
                        color: 'black',
                        fontWeight: 400,
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      Option: IconOption,
                    }}
                  />
                </div>
                <div className="sortByDropdownIB">
                  <Select
                    defaultValue={[]}
                    placeholder={'Sort By'}
                    onChange={(e) => setSortBy(e.value)}
                    styles={{
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '1em',
                        color: 'black',
                        fontWeight: 400,
                      }),
                    }}
                    options={sortByList}
                  />
                </div>
                {/* </div> */}
              </div>
              {sortedData?.length > 0 ? (
                <div id="react-file-drop-space" className="margin-top-bot">
                  <EDMDocumentUpload
                    controller={platformController}
                    content={defaultContent}
                  >
                    <div
                      className={
                        platformController?.fileItems?.documents?.length
                          ? 'folder-visible'
                          : 'folder-visible'
                      }
                    >
                      <FolderView
                        fileItems={
                          new FileItems(gridHeaders, getTableData(sortedData))
                        }
                        properties={false}
                        move={false}
                        rename={false}
                        notes={false}
                        remove={false}
                        download={false}
                        share={false}
                        preview={false}
                        folders={false}
                        paginationPageSize={25}
                        plugins={pluginsArray.filter((x) => x.visible == true)}
                      />
                    </div>
                  </EDMDocumentUpload>
                </div>
              ) : (
                <span className="page-sub-empty-files">
                  There are no documents for this reporting requirement.&nbsp;
                </span>
              )}
            </div>
          </div>
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default InboxRequirement;
